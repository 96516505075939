<template>
  <div class="tabs">
    <div class="tabs-header">
      <span v-if="title" class="tab-title">{{ title }}</span>
      <ul class="tab-choices tab" v-if="tabType=='tab'">
        <li v-for="(tab, index) in tabs"
            :key="tab.title"
            @click.stop.prevent="changeTab(index)"
            :class="['tab-item', {'active': (index == selectedIndex)}]">
          <button class="btn btn-link">{{ tab.title }}</button>
        </li>
      </ul>
      <div class="tab-choices tab-menu" v-if="tabType=='dropdown'">
        <dropdown-list :items="tabItems"
                         :disabled="disabled"
                         v-model="selectedIndex"
                         position="right" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import DropdownList from './dropdown-list.vue';

export default {
  props: {
    title: { type: String },
    type: { type: String, default: 'tab' }, // tab or dropdown
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedIndex: -1,
      tabs: []
    };
  },
  mounted() {
    const activeTabIndex = this.tabs.findIndex((tab) => tab.active);
    this.selectedIndex = activeTabIndex >= 0 ? activeTabIndex : 0;
  },
  methods: {
    changeTab(index) {
      if (this.disabled) return;
      this.selectedIndex = index;
    },
    setTabSelected(index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = (i === index);
      });
    },
  },
  computed: {
    selectedTab() {
      return this.tabs[this.selectedIndex];
    },
    selectedName() {
      return this.selectedTab.title ? this.selectedTab : null;
    },
    tabItems() {
      return this.tabs.map((tab) => tab.title);
    },
    tabType() {
      return this.$screen.sm ? 'dropdown' : this.type;
    }
  },
  watch: {
    selectedIndex(value) {
      this.setTabSelected(value);
    }
  },
  components: { DropdownList }
};
</script>