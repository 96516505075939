<template>
  <div>
    <div :class="['dropdown dropdown-js', {'active': visible, 'dropdown-right': position==='right'}]">
      <dropdown-button :btn-text="selectedName"
                       :has-arrow="hasArrow"
                       :disabled="disabled"
                       :btn-icon="btnIcon"
                       @toggle="toggleDropdown">
        <ul class="menu">
          <li v-if="showSearch" class="menu-item mb-3">
            <input v-model="search" type="text" class="form-input" placeholder="Search..." @click.stop>
          </li>
          <template v-for="(groupItems, groupName) in displayItems" :key="groupName">
            <li v-if="groupName" class="menu-item text-uppercase text-gray text-tiny">{{ groupName }}</li>
            <li v-for="(item, key) in groupItems" :key="key" class="menu-item">
              <button :class="['btn', 'btn-link', {'active': (key === modelValue)}]"
                      @click.stop.prevent="selectItem(key)">
                {{ item.name }}
              </button>
            </li>
          </template>
        </ul>
      </dropdown-button>
    </div>
  </div>
</template>

<script>
import DropdownButton from './dropdown-button.vue';
import ItemList from '../../utility/item-list';

export default {
  components: {
    DropdownButton
  },
  props: {
    modelValue: { required: true },
    items: { type: [Array, Object], required: true },
    position: { type: String, default: 'left' },
    defaultText: { type: String, default: 'Select' },
    btnIcon: { type: String, default: null },
    hasArrow: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: false }
  },
  emits: ['change', 'update:modelValue', 'beforeToggle'],
  data() {
    return {
      visible: false,
      search: ''
    };
  },
  computed: {
    itemList() {
      return new ItemList(this.items);
    },
    selectedName() {
      return this.modelValue != null ? this.itemList.find(this.modelValue.toString()).name : this.defaultText;
    },
    displayItems() {
      return this.itemList.filter(this.search).group();
    }
  },
  methods: {
    toggleDropdown(event) {
      this.$emit('beforeToggle', event);
      if (!event.defaultPrevented) {
        this.visible = !this.visible;
      }
    },
    selectItem(key) {
      if (this.disabled) return;
      this.$emit('update:modelValue', key);
      this.$emit('change', key);
      this.visible = false;
    }
  }
};
</script>
