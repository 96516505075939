import posthog from 'posthog-js';
import captureEvent from './capture-event';
import appConfig from './app-config';
import { isUserSignedIn, currentUserEmail } from './user';

class PosthogManager {
  constructor(env) {
    this.env = env;
    this.apiKey = 'phc_kKKeZHcYnrYENfbkhGT3DZMAmGrF5xcmG1DU2iKSOLZ';
    this.minSessionLength = isUserSignedIn() ? 7000 : 15000;

    this.enableSessionRecording = false;
    this.minSessionLengthReached = false;
    this.sessionRecordingStarted = false;
    this.pageViews = 0;
    this.signUpCaptured = false;
  }

  init() {
    posthog.init(
      this.apiKey,
      {
        api_host: 'https://us.i.posthog.com',
        capture_pageview: false,
        disable_session_recording: true
      }
    );

    this.setupOnLoadActions();

    if (this.enableSessionRecording) {
      this.setupSessionRecording();
    }
  }

  setupOnLoadActions() {
    document.addEventListener('turbolinks:load', () => {
      this.registerGlobalProperties();
      this.capturePageView();
      this.maybeStartSessionRecording();
      this.detectSignUp();
      this.identifyUser();
    });
  }

  registerGlobalProperties() {
    posthog.unregister('page_type');
    posthog.unregister('page_object');

    const props = {};
    const { type, object } = appConfig.get('page_meta', {});
    if (type) { props.page_type = type; }
    if (object) { props.page_object = object; }

    posthog.register(props);
  }

  capturePageView() {
    captureEvent('$pageview');
    this.pageViews += 1;
  }

  detectSignUp() {
    const signUpProps = appConfig.get('sign_up');
    if (signUpProps && !this.signUpCaptured) {
      captureEvent('sign_up', signUpProps);
      this.signUpCaptured = true;
    }
  }

  identifyUser() {
    if (isUserSignedIn()) {
      if (posthog.get_distinct_id() !== currentUserEmail()) {
        posthog.identify(currentUserEmail());
      }
    }
  }

  setupSessionRecording() {
    setTimeout(() => {
      this.minSessionLengthReached = true;
      this.maybeStartSessionRecording();
    }, this.minSessionLength);
  }

  maybeStartSessionRecording() {
    if (!this.enableSessionRecording || !this.minSessionLengthReached || this.sessionRecordingStarted || this.env !== 'production') {
      return;
    }

    if (isUserSignedIn() || this.pageViews > 1) {
      posthog.startSessionRecording();
      this.sessionRecordingStarted = true;
    }
  }
}

let posthogManager = null;

export default (env) => {
  if (!posthogManager) {
    posthogManager = new PosthogManager(env);
    posthogManager.init();
  }
};
