<template>
  <dropdown :btn-text="btnText" class="stock-switch">
    <div class="stock-switch-menu" :class="{ 'stock-switch-menu-empty': !items.length }">
      <remote-search ref="remoteSearch"
                     :path="searchPath"
                     :tabindex="0"
                     placeholder="Search stocks..."
                     @item-select="onItemSelected"
                     @result-changed="onResultChanged">
        <template #item="{ticker, name, url}">
          <a :href="url">
            <span class="label label-secondary">{{ ticker }}</span> - {{ name }}
          </a>
        </template>
      </remote-search>
    </div>
  </dropdown>
</template>

<script>
import Dropdown from './elements/dropdown.vue';
import RemoteSearch from './remote-search.vue';

export default {
  components: { Dropdown, RemoteSearch },
  props: {
    btnText: { type: String, default: '' },
    currentPage: { type: String, required: true }
  },
  data() {
    return {
      items: []
    };
  },
  computed: {
    searchPath() {
      let path = '/stocks/search?limit=3';

      if (this.currentPage !== 'stock_analysis') {
        path += `&page=${this.currentPage}`;
      }

      return path;
    }
  },
  methods: {
    onItemSelected(item) {
      window.location = item.url;
    },
    onResultChanged(items) {
      this.items = items;
    }
  }
};
</script>
