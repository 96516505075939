import PriceChart from './components/charts/price-chart.vue';
import Chart from './components/charts/chart.vue';
import RemoteChartTabs from './components/charts/remote-chart-tabs.vue';
import ReadMore from './components/elements/read-more.vue';
import InternalNavigation from './components/internal-navigation.vue';
import ImageSrcset from './components/elements/image-srcset.vue';
import initializeVue from './utility/initialize-vue';
import PieChart from './components/charts/pie-chart.vue';
import Ad from './components/ad.vue';
import CollapseTable from './directives/collapse-table';
import WatchlistWidget from "./components/account/watchlist-widget.vue";
import StockSwitch from './components/stock-switch.vue';

initializeVue(
  'stock-profile',
  {
    components: {
      ReadMore, Chart, RemoteChartTabs, PriceChart, InternalNavigation, PieChart, Ad, WatchlistWidget, StockSwitch, ImageSrcset
    },
    directives: {
      CollapseTable
    }
  },
  {
    useVueScrollTo: true
  }
);
