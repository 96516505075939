<template>
  <dropdown ref="dropdown"
            :btn-text="selectedName"
            :has-arrow="hasArrow"
            :primary="false"
            :disabled="disabled"
            tag-name="ul">
    <li v-for="(name, index) in items" :key="name" class="menu-item">
      <button :class="['btn', 'btn-link', {'active': (index === modelValue)}]"
              @click.stop.prevent="selectItem(index)">
        {{ name }}
      </button>
    </li>
  </dropdown>
</template>

<script>

import Dropdown from './dropdown.vue';

export default {
  components: { Dropdown },
  props: {
    modelValue: { required: true },
    items: { type: [Array, Object], required: true },
    position: { type: String, default: 'left' },
    defaultText: { type: String, default: 'Select' },
    hasArrow: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    selectedName() {
      return this.modelValue != null ? this.items[this.modelValue] : this.defaultText;
    }
  },
  methods: {
    selectItem(key) {
      if (this.disabled) return;
      this.$emit('update:modelValue', key);
      this.$emit('change', key);
      this.$refs.dropdown.close();
    }
  }
};
</script>
