<template>
  <div v-click-outside="close"
       :class="['dropdown dropdown-js', {'active': visible, 'dropdown-right': position==='right'}]">
    <dropdown-button :btn-text="btnText"
                     :has-arrow="hasArrow"
                     :primary=primary
                     :disabled="disabled"
                     @toggle="toggleDropdown" />
    <component :is="tagName" class="menu">
      <slot />
    </component>
  </div>
</template>

<script>
import DropdownButton from './dropdown-button.vue';
import ClickOutside from '../../directives/click-outside';

export default {
  components: { DropdownButton },
  directives: { ClickOutside },
  props: {
    btnText: { type: String, default: '' },
    primary: { type: Boolean, default: false },
    position: { type: String, default: 'left' },
    hasArrow: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    tagName: { type: String, default: 'div' }
  },
  emits: ['open', 'close'],
  data() {
    return {
      visible: false
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.$emit('open');
    },
    close() {
      this.visible = false;
      this.$emit('close');
    },
    toggleDropdown() {
      this.visible ? this.close() : this.open();
    }
  }
};
</script>
