<template>
  <ul class="pagination" v-if="totalPages > 1">
    <!-- Previous Button -->
    <li v-if="page > 1" class="page-item">
      <a href="#" @click.prevent="changePage(page - 1)" tabindex="-1" rel="prev">Prev</a>
    </li>

    <!-- First Page -->
    <li class="page-item" :class="{ active: page === 1 }">
      <a href="#" v-if="page !== 1" @click.prevent="changePage(1)">1</a>
      <span v-else>1</span>
    </li>

    <!-- Dots Before Current Page Range -->
    <li v-if="shouldShowStartEllipsis" class="page-item disabled">
      <span>...</span>
    </li>

    <!-- Pages Near Current Page -->
    <li v-for="pageNumber in pageRange" :key="pageNumber" class="page-item" :class="{ active: pageNumber === page }">
      <a href="#" v-if="pageNumber !== page" @click.prevent="changePage(pageNumber)">{{ pageNumber }}</a>
      <span v-else>{{ pageNumber }}</span>
    </li>

    <!-- Dots After Current Page Range -->
    <li v-if="shouldShowEndEllipsis" class="page-item disabled"><span>...</span></li>

    <!-- Last Page -->
    <li class="page-item" :class="{ active: page === totalPages }">
      <a href="#" v-if="page !== totalPages" @click.prevent="changePage(totalPages)">{{ totalPages }}</a>
      <span v-else>{{ totalPages }}</span>
    </li>

    <!-- Next Button -->
    <li v-if="page < totalPages" class="page-item">
      <a href="#" @click.prevent="changePage(page + 1)" rel="next">Next</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    totalCount: { type: Number, required: true },
    perPage: { type: Number, required: true },
    page: { type: Number, required: true }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    pageRange() {
      const start = Math.max(this.page - 2, 2); // Start from 2 because 1 is handled separately
      const end = Math.min(this.page + 2, this.totalPages - 1); // TotalPages - 1 as the last page is handled separately
      const range = [];
      for (let i = start; i <= end; i += 1) {
        range.push(i);
      }
      return range;
    },
    shouldShowStartEllipsis() {
      return this.page > 4;
    },
    shouldShowEndEllipsis() {
      return this.page < this.totalPages - 3;
    }
  },
  methods: {
    changePage(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.$emit('page-change', newPage);
      }
    }
  }
};
</script>
