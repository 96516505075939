<template>
  <modal title="Sign up or Log in"
         ref="modal"
         @hide="onHide">
    <div class="text-center pb-5">
      <p>{{ message }}</p>
      <require-login-buttons />
    </div>
  </modal>
</template>

<script>
import Modal from './elements/modal.vue';
import RequireLoginButtons from './require-login-buttons.vue';
import captureEvent from '../utility/capture-event';

export default {
  components: { Modal, RequireLoginButtons },
  data() {
    return {
      defaultMessage: 'You need a free account to use this feature',
      message: this.defaultMessage
    };
  },
  methods: {
    show(message) {
      this.message = message || this.defaultMessage;
      this.$refs.modal.show();
      captureEvent('require_login');
    },
    onHide() {
      this.message = this.defaultMessage;
    }
  }
};
</script>
