<template>
  <div class="stock-screener-filters mb-4"
       :class="[hasFilters ? 'stock-screener-filters-applied' : 'stock-screener-filters-not-applied']">
    <div class="stock-screener-filters-wrap">
      <stock-screener-filter v-for="(filter) in filters"
                             :key="filter.key"
                             :filter="filter"
                             :opened="!filter.applied"
                             :disabled="loading"
                             class="d-inline-block"
                             @change="updateFilter(filter, $event)"
                             @remove="removeFilter(filter.key)" />
    </div>
    <div v-if="canAddFilter" class="d-inline-block mr-3">
      <dropdown-list-advanced v-model="newFilterKey"
                     :items="filterList"
                     position="left"
                     :default-text="buttonText"
                     :has-arrow="!hasFilters"
                     :disabled="loading"
                     :show-search="true"
                     btn-icon="icon-plus"
                     class="stock-screener-filters-select"
                     @before-toggle="onBeforeToggle"
                     @change="addFilter()" />
    </div>
    <button v-if="filters.length > 1"
            class="btn btn-link"
            @click="removeAllFilters">
      Clear all
    </button>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'pinia';
import { useStockScreenerStore } from '../../stores/stock-screener-store';
import DropdownListAdvanced from '../elements/dropdown-list-advanced.vue';
import StockScreenerFilter from './stock-screener-filter.vue';

export default {
  components: {
    DropdownListAdvanced, StockScreenerFilter
  },
  data() {
    return {
      newFilterKey: null
    };
  },
  computed: {
    ...mapState(useStockScreenerStore, ['loading', 'filters', 'filterList', 'hasUnappliedFilters']),
    canAddFilter() {
      return !this.hasUnappliedFilters && !_.isEmpty(this.filterList);
    },
    buttonText() {
      return this.hasFilters ? null : 'Add Filter';
    },
    hasFilters() {
      return this.filters.length > 0;
    }
  },
  methods: {
    ...mapActions(useStockScreenerStore, ['createFilter', 'updateFilter', 'removeFilter', 'removeAllFilters']),
    onBeforeToggle(event) {
      if (!this.userSignedIn && this.filters.length >= 1) {
        event.preventDefault();
        this.requireLogin('You need a free account to add more filters.');
      }
    },
    addFilter() {
      this.createFilter(this.newFilterKey);
      this.newFilterKey = null;
    }
  }
};

</script>
