class AppConfig {
  constructor() {
    this.config = window.appConfig || {};

    // reload config on turbolinks load
    document.addEventListener('turbolinks:load', () => {
      this.config = window.appConfig || {};
    }, true);
  }

  get(key, defaultValue = null) {
    return Object.prototype.hasOwnProperty.call(this.config, key) ? this.config[key] : defaultValue;
  }
}

const appConfig = new AppConfig();
export default appConfig;
