<template>
  <a href="/account/auth/google_oauth2" rel="nofollow" data-method="post" class="google-sign-up-btn mr-3"></a>
  <div class="divider" data-content="OR"></div>
  <div class="mt-5">
    <a class="btn btn-primary mr-3" href="/account/sign_up">Sign up with email</a>
    <a class="btn btn-outline-primary" href="/account/sign_in">Sign in</a>
  </div>
</template>

<script>
export default {};
</script>
