import { isUserSignedIn } from '../utility/user';
import captureEvent from '../utility/capture-event';

class CollapsibleTable {
  constructor(table, numberOfRowsToShow, authorizeExpand) {
    this.table = table;
    this.numberOfRowsToShow = numberOfRowsToShow;
    this.authorizeExpand = authorizeExpand;
    this.collapsed = false;
  }

  init() {
    const rows = Array.from(this.table.querySelectorAll('tr'));
    this.collapsibleRows = rows.slice(this.numberOfRowsToShow);

    if (!this.collapsibleRows.length) return;

    this.addToggleButton();
    this.collapse();
  }

  addToggleButton() {
    this.toggleBtn = document.createElement('button');
    this.btnIcon = document.createElement('i');
    this.btnText = document.createTextNode('');

    this.toggleBtn.className = 'btn btn-block btn-collapse';
    this.toggleBtn.appendChild(this.btnIcon);
    this.toggleBtn.appendChild(this.btnText);
    this.toggleBtn.addEventListener('click', () => { this.collapsed ? this.expand() : this.collapse(); });
    this.table.after(this.toggleBtn);
  }

  collapse() {
    this.collapsibleRows.forEach((row) => { row.style.display = 'none'; });
    this.collapsed = true;
    this.btnText.textContent = 'Show more';
    this.btnIcon.className = 'icon-chevron-down';
  }

  expand() {
    captureEvent('expand_table');

    if (!this.authorizeExpand()) {
      return;
    }

    this.collapsibleRows.forEach((row) => { row.style.display = 'table-row'; });
    this.collapsed = false;
    this.btnText.textContent = 'Show less';
    this.btnIcon.className = 'icon-chevron-up';
  }
}

export default {
  mounted(el, binding) {
    const numberOfRowsToShow = binding.value;
    const loginRequired = binding.modifiers['require-login'] === true;
    const app = binding.instance;

    const authorizeExpand = () => {
      if (!loginRequired || isUserSignedIn()) return true;

      app.requireLogin('You need a free account to access this data');
      return false;
    };

    const collapsibleTable = new CollapsibleTable(el, numberOfRowsToShow, authorizeExpand);
    collapsibleTable.init();
  }
};
