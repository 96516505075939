<template>
  <div class="empty empty-darker">
    <div class="empty-icon">
      <i :class="iconClass"></i>
    </div>
    <p class="empty-title h5">
      {{ text }}
    </p>

    <p v-if="subtitle" class="empty-subtitle">
      {{ subtitle }}
    </p>

    <div class="empty-action">
      <require-login-buttons />
    </div>
  </div>
</template>

<script>
import RequireLoginButtons from './require-login-buttons.vue';

export default {
  components: { RequireLoginButtons },
  props: {
    icon: { type: String, default: 'lock' },
    text: { type: String, default: 'You need a free account to use this feature' },
    subtitle: { type: String, default: null }
  },
  computed: {
    iconClass() {
      return `icon-2x icon-${this.icon}`;
    }
  }
};
</script>
