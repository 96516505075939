<template>
  <img :src="current_image_path" />
</template>

<script>
import { merge } from 'lodash';

export default {
  props: {
    src: { type: String, required: true },
    srcset: { type: Object, required: true }
  },
  data() {
    return {
      sizes: merge(this.srcset, { default: this.src })
    };
  },
  computed: {
    current_image_path() {
      return this.$screen.findOptionForCurrentSize(this.sizes);
    }
  }
};
</script>
